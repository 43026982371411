import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { getResortData } from "../services/dataServices";
import { useFormik } from "formik";
import * as Yup from "yup";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useMutation, useQuery } from "react-query";
import { sendSadarManzilBookNowEmail, getVillasType } from "../services/dataServices";
import { toast } from "react-toastify";
import Collapse from 'react-bootstrap/Collapse';
import Scrollbars from "react-custom-scrollbars";
import Button from 'react-bootstrap/Button';
export const formSchema = Yup.object({
  firstName: Yup.string().trim().required("Please enter first name"),
  email: Yup.string().email().required("Please enter email"),
  phone: Yup.string().required("Please enter phone number"),
  //  phone: Yup.string().required("Please enter phone number"),

});
function Modals({ show, handleClose, formData = "", bookingStart = "", bookingEnd = "" }) {

  let currentDateTime = new Date();
  const currentDate = currentDateTime.getDate();
  const nextDate = new Date(currentDateTime.setDate(currentDate + 1));
  const [bookingStartDate, setStart] = useState(new Date());
  const [bookingEndDate, setEnd] = useState(nextDate);

  const [isLoading, setIsLoading] = useState(false);
  const allResortdata = useQuery("allresortdata", getResortData);
  const villaType = useQuery("villaTypes", getVillasType);
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(show);
  useEffect(() => {
    setModalIsOpen(show);
  }, [show]);

  let bookNowDataFromFrontPage = { ...formData, bookingStart, bookingEnd }

  const initialValues = {
    title: "Mr.",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    villaType: "",
    discountCode: bookNowDataFromFrontPage.discountCode ? bookNowDataFromFrontPage.discountCode : "",
    roomCount: "1",
    iataCode: bookNowDataFromFrontPage.iataCode ? bookNowDataFromFrontPage.iataCode : "",
    childrenCount:bookNowDataFromFrontPage.childrenCount > 0 ? bookNowDataFromFrontPage.childrenCount : 0,
    adultCount: `${bookNowDataFromFrontPage.adultCount > 2 ? bookNowDataFromFrontPage.adultCount : "2"}`,
    childage: Array(0).fill(6),
    // sBookingDate:bookNowDataFromFrontPage.bookingStart?bookNowDataFromFrontPage.bookingStart:"",
    // eBookingDate:bookNowDataFromFrontPage.bookingEnd?bookNowDataFromFrontPage.bookingEnd:"",
  };


  useEffect(() => {
    setValues({ ...initialValues });
  }, [formData]);


  useEffect(() => {

    if (bookingStart && bookingEnd) {
      setStart(new Date(bookingStart));
      setEnd(new Date(bookingEnd));
    }
  }, [bookingStart, bookingEnd]);



  const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue, resetForm, setValues } =
    useFormik({
      initialValues: initialValues,
      validationSchema: formSchema,
      validateOnBlur: true,
      onSubmit: (values, action) => {

        const formData = new FormData();
        formData.append("title", values.title);
        formData.append("firstName", values.firstName);
        formData.append("lastName", values.lastName);
        formData.append("email", values.email);
        formData.append("phone", values.phone);
        formData.append("villaType", values.villaType);
        formData.append("discountCode", values.discountCode);
        formData.append("roomCount", values.roomCount);
        formData.append("iataCode", values.iataCode);
        formData.append("childrenCount", values.childrenCount);
        formData.append("childage", values.childage);
        formData.append("adultCount", values.adultCount);
        formData.append("bookingStartDate", bookingStartDate);
        formData.append("bookingEndDate", bookingEndDate)
        addnewprojectMutation.mutate({ ...values, bookingStartDate, bookingEndDate });

      },
    });
  const addnewprojectMutation = useMutation(sendSadarManzilBookNowEmail, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setSuccessMsg(data.message); // Set success message on email send success
      toast.dismiss();
      setTimeout(() => setSuccessMsg(""), 5000);
      resetForm();
      // setShow(false);
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  // Format date to MM-DD-YYYY
  const formatDate = (date) => format(date, "MM/dd/yyyy");

  const adultIncrementBtn = (e) => {
    e.preventDefault()
    setFieldValue("adultCount", parseInt(values.adultCount) + 1);

  };

  const adultDecrementBtn = (e) => {
    e.preventDefault()
    if (values.adultCount > 2) {
      setFieldValue("adultCount", parseInt(values.adultCount) - 1);

    }
  };


  const childrenIncrement = (e) => {
    e.preventDefault()
    setFieldValue("childrenCount", parseInt(values.childrenCount) + 1);
  };

  const childrenDecrement = (e) => {
    e.preventDefault()
    if (values.childrenCount) {
      setFieldValue("childrenCount", parseInt(values.childrenCount) - 1);

    }
  };
  // };
  const roomIncrementBtn = (event) => {
    event.preventDefault();
    setFieldValue("roomCount", parseInt(values.roomCount) + 1);
  };

  const roomDecrementBtn = (event) => {
    event.preventDefault();
    if (values.roomCount > 1) {
      setFieldValue("roomCount", parseInt(values.roomCount) - 1);
    }
  };


  const handlePhoneChange = (value) => {
    // Check if value is not undefined before setting field value
    if (value !== undefined) {
      setFieldValue('phone', value);
    }
  };
  //   const closeButton = () => {
  //     setModalIsOpen(false);
  //     onClose();
  //     resetForm();
  //     // setValues(null)
  //     setSuccessMsg("");
  //   };
  console.log("ini",initialValues)
  const handleChildrenCountChange = (event, increment) => {
    event.preventDefault();
  
    // Get the current value of childrenCount
    const currentCount = values.childrenCount;
  
    // Calculate the new count
    const newCount = Math.max(0, currentCount + increment); // Prevent negative values
  
    // Update the value of childrenCount
    setFieldValue("childrenCount", newCount);
  
    // Optionally, update related fields (e.g., childage)
    const updatedAges = [...values.childage];
    if (newCount > currentCount) {
      // Add default ages for new children
      for (let i = currentCount; i < newCount; i++) {
        updatedAges.push(6); // Default age
      }
    } else if (newCount < currentCount) {
      // Remove excess ages if the count decreases
      updatedAges.splice(newCount);
    }
  
    setFieldValue("childage", updatedAges);
  };
  
  
  
  const handleChildAgeChange = (index, increment) => {
    const newChildrenAges = [...values.childage]; // Copy current childage array
    const newAge = Math.max(0, Math.min(11, (newChildrenAges[index] || 0) + increment)); // Ensure age is between 0 and 11
    newChildrenAges[index] = newAge; // Update the age at the given index
  
    setFieldValue("childage", newChildrenAges); // Update the form state
  };


  const handlesClose = () => {
    setModalIsOpen(false);
    handleClose()
    resetForm();
    setSuccessMsg("");
  };

  console.log("values.childrenCount",values.childrenCount)

  const [paddingRight, setPaddingRight] = useState(0);
  
    const handleUpdate = ({ scrollHeight, clientHeight }) => {
      // Check if the scrollbar is visible
      if (scrollHeight > clientHeight) {
        setPaddingRight(16); // Width of the scrollbar (adjust as needed)
      } else {
        setPaddingRight(0); // No scrollbar, remove padding
      }
    };

  return (
    <Modal className="book-now-modal" show={modalIsOpen} onHide={handlesClose}>
      <Modal.Header className="border-0 px-xl-5 px-md-4 px-3" closeButton>
        <Modal.Title className="h2 text-white georgia text-uppercase">
          BOOK YOUR STAY
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-xl-5 px-md-4 px-3">
        <form>
          <div className="personal-details mt-xl-3">
            <div className="detail-label p-0 text-uppercase text-white georgia sub-card-title h-auto mb-lg-4 mt-md-0 mt-2 mb-3">
              PERSONAL DETAILS
            </div>
            <div className="row mb-4">
              <div className="col-sm-6">
                <Form.Label htmlFor="Resort" className="text-uppercase text-white">
                  First Name
                </Form.Label>
                <span className="mandatory text-danger"> *</span>
                <div className="form-control prent-form-control ps-0 d-flex align-items-center mb-4">
                  <div className="select-label ps-3">
                    <select className="border-0 bg-transparent ps-2" name="title" values={values.title} onChange={handleChange}>
                      <option value={'Mr.'} selected="Mr.">Mr.</option>
                      <option value={'Mrs'}>Mrs.</option>
                      <option value={'Ms'}>Ms.</option>
                    </select>
                  </div>
                  <div className="value-input inpt-phone-fld border-0 bg-transparent shadow-none w-100">
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      className="border-0 px-2 bg-transparent shadow-none outline-0 w-100"
                      placeholder="First Name"
                      aria-describedby="helpId"
                      maxLength={100}
                    />
                  </div>


                </div>
                {errors.firstName && touched.firstName ? (
                  <p className="form-error text-start text-danger">
                    {errors.firstName}
                  </p>
                ) :
                  null}
              </div>
              <div className="col-sm-6">
                <div className="mb-4">
                  <Form.Label htmlFor="Resort" className="text-uppercase text-white">
                    Last Name
                  </Form.Label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    className="form-control"
                    value={values.lastName}
                    onChange={handleChange}
                    placeholder="Last Name"
                    aria-describedby="helpId"
                    maxLength={100}

                  />
                </div>

              </div>
              {/* /col-6 */}
              <div className="col-sm-6">
                <div className="mb-4">
                  <Form.Label htmlFor="Resort" className="text-uppercase text-white">
                    Email address
                  </Form.Label>
                  <span className="mandatory text-danger"> *</span>

                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    value={values.email}
                    onChange={handleChange}
                    placeholder="Email Address"
                    aria-describedby="helpId"
                    maxLength={80}

                  />
                </div>
                {errors.email && touched.email ? (
                  <p className="form-error text-start text-danger">
                    {errors.email}
                  </p>
                ) :
                  null}
              </div>
              {/* <div className="col-md-6">
                <div className="mb-4">
                  <Form.Label htmlFor="Resort" className="text-uppercase text-white">
                    Phone Number
                  </Form.Label>
                  <input type="text"
                            // international
                            // defaultCountry="RU"
                            value={values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="phone"
                            name="phone"
                            className="form-control"
                            placeholder="Phone Number"
                          />

                </div>
                {errors.phone && touched.phone ? (
                            <p className="form-error text-start text-danger">
                              {errors.phone}
                            </p>
                          ) : 
                           null}
              </div> */}
              <div className="col-sm-6">
                <div className="mb-4">
                  <Form.Label htmlFor="Resort" className="text-uppercase text-white">
                    Phone Number
                  </Form.Label>
                  <span className="mandatory text-danger"> *</span>
                  <PhoneInput
                    international
                    // defaultCountry=
                    country={undefined}
                    value={values.phone}
                    // onChange={handleChange}
                    onChange={handlePhoneChange}
                    onBlur={handleBlur}
                    id="phone"
                    name="phone"
                    className="form-control d-flex mb-4 inpt-phone-fld"
                    placeholder="Phone Number"
                    maxlength={20}

                  />

                </div>
                {errors.phone && touched.phone ? (
                  <p className="form-error text-start text-danger">
                    {errors.phone}
                  </p>
                ) :
                  null}
              </div>
            </div>
            {/* row */}
          </div>
          {/* /personal details */}

          <div className="booking-details mt-lg-4 mt-3">
            <div className="detail-label p-0 text-uppercase text-white georgia sub-card-title h-auto mb-lg-4  mb-3">
              BOOKING DETAILS
            </div>
            <div className="mb-4">
              <Form.Label htmlFor="Resort" className="text-uppercase text-white">
                Room Type
              </Form.Label>
              <select className="form-control form-select custom-down-arrow" name="villaType" value={values.villaType} onChange={handleChange}>
                <option >Select Room Type</option>

                {villaType?.data &&
                  villaType?.data?.data?.map((option) => (
                    <option
                      key={option.property_id}
                      value={option.property_name}
                    >
                      {option.property_name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="mb-4">
              <Form.Label htmlFor="Resort" className="text-uppercase text-white">
                Check in <span className="mx-1">-</span> Check out
              </Form.Label>
              <DateRangePicker
                initialSettings={{
                  locale: {
                    format: "DD/MM/YYYY",
                  },
                  startDate: bookingStartDate,
                  endDate: bookingEndDate,
                  minDate: new Date(),
                  autoApply: true,
                }}
                onApply={(event, picker) => {
                  setStart(new Date(picker.startDate));
                  setEnd(new Date(picker.endDate));
                }}
              >
                <input
                  type="text"
                  className="form-control shadow-none text-start "
                />
              </DateRangePicker>
            </div>
            <div className="row mb-4 gy-sm-0 gy-3">
              <div className="col-sm-4">
                <Form.Label className="text-uppercase text-white">
                  Rooms
                </Form.Label>
                <div className="input-number position-relative">
                  <button
                    className="input-number-increment"
                    onClick={(e) => roomDecrementBtn(e)}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </button>
                  <input
                    type="number"
                    value={values.roomCount}
                    defaultValue={values.roomCount}
                    id="inuput-number"
                    className="form-control shadow-none text-center input-arrow"
                  />
                  <button
                    className="input-number-decrement"
                    onClick={(e) => roomIncrementBtn(e)}
                  >
                    <FontAwesomeIcon icon={faAngleRight} />
                  </button>
                </div>
              </div>
              <div className="col-sm-4">
                <Form.Label className="text-uppercase text-white">
                  Adults
                </Form.Label>
                <div className="input-number position-relative">
                  <button
                    className="input-number-increment"
                    onClick={(e) => adultDecrementBtn(e)}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </button>
                  <Form.Control
                    type="number"
                    id="inuput-number"
                    className="form-control shadow-none text-center"
                    value={values.adultCount}
                    defaultValue={values.adultCount}
                  />
                  <button
                    className="input-number-decrement"
                    onClick={(e) => adultIncrementBtn(e)}
                  >
                    <FontAwesomeIcon icon={faAngleRight} />
                  </button>
                </div>
              </div>
              <div className="col-sm-4">
                <Form.Label className="text-uppercase text-white">
                  Children
                </Form.Label>
                <Button
                  // onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  className="p-0 border-0 bg-transparent shadow-none chrld-btn w-100"
                // aria-expanded={open}
                >
                  <div className="input-number position-relative">

                    <div
                      className="input-number-increment h-100 d-flex justify-content-end align-items-center"
                      onClick={(e) => handleChildrenCountChange(e, -1, "childrenCount")}
                    >
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </div>

                    <Form.Control
                     
                      type="number"
                      id="inuput-number"
                      className="form-control shadow-none text-center border-0"
                      value={values.childrenCount}
                    />
                    <div
                      className="input-number-decrement h-100 d-flex justify-content-start align-items-center"
                      onClick={(e) => handleChildrenCountChange(e, +1, "childrenCount")}
                    >
                      <FontAwesomeIcon icon={faAngleRight} />
                    </div>
                  </div>
                </Button>

              </div>
            </div>
            {/* /row */}
            <div className="mb-4" id="example-collapse-text">
            {/* <Scrollbars autoHeight autoHeightMax={220} onUpdate={handleUpdate}>
            <div className="w-full overflow-hidden" style={{ paddingRight: `${paddingRight}px`,}}> */}
              <div className="row gy-3">
                {values?.childage?.map((age, index) => (
                  <div className="col-sm-4 col-6" key={index}>
                    {/* <label className='form-label uppercase mb-1 flex'>Child {index + 1} AGE</label> */}
                    <Form.Label className="text-uppercase text-white" >
                      Child {index + 1} AGE
                    </Form.Label>
                    <div className="input-number position-relative" >
                      <span
                        className="input-number-increment h-100 d-flex align-items-center justify-content-end cursor-pointer" onClick={() => handleChildAgeChange(index, -1)}><FontAwesomeIcon icon={faAngleLeft} /></span>
                      <Form.Control type='number'  value={age} className="form-control shadow-none text-center" />
                      <span
                        className="input-number-decrement h-100 d-flex align-items-center  cursor-pointer" onClick={() => handleChildAgeChange(index, 1)}> <FontAwesomeIcon icon={faAngleRight} /></span>
                    </div>
                  </div>

                ))}

              </div>
              {/* </div>
              </Scrollbars> */}
            </div>
            {/* <Collapse in={open}>
          <div className="mb-4" id="example-collapse-text">
            <div className="row gy-sm-0 gy-3">
              <div className="col-sm-4">
              <Form.Label className="text-uppercase text-white">
                  Child1 AGE
                </Form.Label>
                <div className="input-number position-relative">
                  <button
                    className="input-number-increment"
                    onClick={adultDecrementBtn}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </button>
                  <Form.Control
                    type="number"
                    id="inuput-number"
                    className="form-control shadow-none text-center"
                    value={formData.adultCount}
                  />
                  <button
                    className="input-number-decrement"
                    onClick={adultIncrementBtn}
                  >
                    <FontAwesomeIcon icon={faAngleRight} />
                  </button>
                </div>
            </div>
            <div className="col-sm-4">
              <Form.Label className="text-uppercase text-white">
                  Child2 AGE
                </Form.Label>
                <div className="input-number position-relative">
                  <button
                    className="input-number-increment"
                    onClick={adultDecrementBtn}
                  >
                    <FontAwesomeIcon icon={faAngleLeft} />
                  </button>
                  <Form.Control
                    type="number"
                    id="inuput-number"
                    className="form-control shadow-none text-center"
                    value={formData.adultCount}
                  />
                  <button
                    className="input-number-decrement"
                    onClick={adultIncrementBtn}
                  >
                    <FontAwesomeIcon icon={faAngleRight} />
                  </button>
                </div>
            </div>
        </div>
          </div>
      </Collapse> */}
            <div className="row mb-4 gy-sm-0 gy-3">
              <div className="col-sm-6">
                <Form.Label className="text-uppercase text-white">
                  Discount Code
                </Form.Label>
                <Form.Control
                  value={values.discountCode}
                  type="text"
                  className="form-control shadow-none"
                  placeholder="Enter code here"
                  onChange={handleChange}
                  name="discountCode"
                  id="discountCode"
                  maxLength={80}

                />
              </div>
              <div className="col-sm-6">
                <Form.Label className="text-uppercase text-white">
                  IATA Code
                </Form.Label>
                <Form.Control
                  value={values.iataCode}
                  type="text"
                  className="form-control shadow-none"
                  placeholder="Enter code here"
                  onChange={handleChange}
                  name="iataCode"
                  id="iataCode"
                  maxLength={80}

                />
              </div>
            </div>
            {/* /row */}
            <div className="valid-info text-white mb-3">
              <span>As we revitalize our brand, our resort booking system is upgrading soon. For rates and availability, our reservation team is ready to assist upon form submission</span>
            </div>
          </div>
          {/* /personal details */}
          <div className="pt-3">
            <div className="form-submit">

              {addnewprojectMutation.isLoading ? (
                <button
                  className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden" role="status">
                    Loading...
                  </span>
                </button>) : successMsg ? (
                  <span className="text-white">{successMsg}</span>
                ) : (
                <input
                  type="submit"
                  className="btn primary theme-btn fw-medium text-white text-uppercase border-theme"
                  value={"BOOK NOW"}
                  onClick={handleSubmit}
                />
              )


              }
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default Modals;
