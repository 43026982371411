import React, { useContext, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import ExperienceTabs from "./ExperienceTabs";
import ExperienceAwait from "../../components/ExperienceAwait";
import Helmet from "../../components/Helmet";
import bannerCover from "../../assets/defaultBannerAndIcon/banner-placehoder.jpg";
import { getPageData, getPageData1 } from "../../services/dataServices";
import { useQuery } from "react-query";
import BannerImage from "../../components/BannerImage";

function Experiences({ isPreview, userId, sectionId }) {
  const pageCode = "SEXPERIENCES";
  // const { setIsLoadingMain } = useContext(MyContext);
  const [isBannerLoading, setIsBannerLoading] = useState(true);
  const pagesSectionData1 = useQuery(
    ["bannerVideo", pageCode, isPreview, userId, sectionId],
    () => getPageData1(pageCode, isPreview, userId, sectionId)
  );

  const Bannerimage =
    pagesSectionData1?.data?.sectionData?.filter(
      (item) =>
        item.page_template_code === "SEXPERIENCES" &&
        item.section_code === "MAINBANNER" &&
        item.field_label_code === "BANNERIMAGE"
    )[0]?.psdd_data;

  const pagesSectionData = useQuery(
    ["pagesSectionData", pageCode, isPreview, userId, sectionId],
    () => getPageData(pageCode, isPreview, userId, sectionId)
  );

  const experiencesData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "MAINBANNER"
      )[0] ?? null
  );

  const BannerImageAltTag =
    experiencesData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "BANNERIMAGE"
    )[0]?.img_alt_tag ?? null;

  const experienceAwaitData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "BOUNDLESS"
      )[0] ?? null
  );
  const experienceAwaitIcon =
    experienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "ICON"
    )[0]?.data;
  const experienceAwaitHeading =
    experienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "HEADING"
    )[0]?.data ?? null;

  const description =
    experienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "DESCRIPTION"
    )[0]?.data ?? null;

  let keywords = pagesSectionData?.data?.data[0]?.meta_keyword;
  let slug = pagesSectionData?.data?.data[0]?.slug;
  let meta_title = pagesSectionData?.data?.data[0]?.meta_title;
  let meta_description = pagesSectionData?.data?.data[0]?.meta_description;

  // const handleImageLoad = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  // const handleError = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  // if (pagesSectionData1.isLoading) {
  //   setIsLoadingMain(true);
  //   document.body.classList.add('slider-open');
  //   return <Loader />;
  // }
  // else if (isBannerLoading) {
  //   document.body.classList.add('slider-open');
  //   setIsLoadingMain(true);
  // } 

  return (
    <>
      <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100 cover-overlay">
            {/* <img
              className="w-100 h-100"
              src={BannerImage}
              title=""
              alt={BannerImageAltTag}
              onLoad={handleImageLoad}
              onError={handleError}
            /> */}
            <BannerImage
              className="w-100 h-100"
              src={Bannerimage}
              title=""
              alt={BannerImageAltTag}
              isDataIsLoading={pagesSectionData1.isLoading}
            />
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                experiences
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>experiences</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>

      <ExperienceAwait
        image={experienceAwaitIcon}
        showLargeheading={true}
        showHeading={false}
        heading={experienceAwaitHeading}
        para={description}
      />

      <section>
        <div className="experience-tabs-view new-exprnc-tabs section-padding pt-0">
          <ExperienceTabs pagesSectionData={pagesSectionData} />
        </div>
      </section>
    </>
  );
}

export default Experiences;
