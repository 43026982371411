import React, { useContext } from "react";
import HomeVideo from "../../components/HomeVideo";
import BookNow from "../../components/BookNow";
import ExperienceAwait from "../../components/ExperienceAwait";
import SpecialOffers from "../../components/SpecialOffers";
import Sustainability from "../../components/Sustainability";
import KanifushPlan from "../../components/KanifushPlan";
import ExploreVillas from "../../components/ExploreVillas";
import Glimpses from "../../components/Glimpses";
import Helmet from "../../components/Helmet";
import { getPageData, getPageData1 } from "../../services/dataServices";
import { useQuery } from "react-query";
import bannerCover from "../../assets/defaultBannerAndIcon/banner-placehoder.jpg";
import { MyContext } from "../../Contex/LoadingContex";

const Home = ({
  isPreview,
  userId,
  sectionId,
  villaSlug,
  planSlug,
  offersSlug,
  sustainibilitySlug,
  slugOfGallery,
  slugOfDiningOffers="",
  slugOfRoomOffers="",
  slugOfSpaOffers=""
}) => {
  const pageCode = "SHOME";
  const pagesSectionData1 = useQuery(
    ["pagesSectionData1", pageCode, isPreview, userId, sectionId],
    () => getPageData1(pageCode, isPreview, userId, sectionId)
  );

  const pagesSectionData = useQuery(
    ["pagesSectionData", pageCode, isPreview, userId, sectionId],
    () => getPageData(pageCode, isPreview, userId, sectionId)
  );

  const glimpseData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "GLIMPSES"
      )[0] ?? null
  );
  const Image1 =
    glimpseData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "IMAGE5"
    )[0]?.data ?? null;

  const Image2 =
    glimpseData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "IMAGE2"
    )[0]?.data ?? null;

  const Image3 =
    glimpseData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "IMAGE4"
    )[0]?.data ?? null;

  const Image4 =
    glimpseData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "IMAGE3"
    )[0]?.data ?? null;

  const Image5 =
    glimpseData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "IMAGE6"
    )[0]?.data ?? null;

  const Image6 =
    glimpseData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "IMAGE1"
    )[0]?.data ?? null;

  const topLeftImagealt =
    glimpseData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "IMAGE1"
    )[0]?.img_alt_tag ?? null;

  const topRightImagealt =
    glimpseData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "IMAGE5"
    )[0]?.img_alt_tag ?? null;

  const bottomLeftImagealt =
    glimpseData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "IMAGE2"
    )[0]?.img_alt_tag ?? null;

  const bottomCenterImagealt =
    glimpseData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "IMAGE4"
    )[0]?.img_alt_tag ?? null;

  const topCenterImagealt =
    glimpseData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "IMAGE3"
    )[0]?.img_alt_tag ?? null;

  const bottomRightImagealt =
    glimpseData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "IMAGE6"
    )[0]?.img_alt_tag ?? null;

  const sustainAbilityData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "SUSTAINABILITY"
      )[0] ?? null
  );

  const sustainAbilityHeading =
    sustainAbilityData?.[0]?.section_elements.filter(
      (item) => item.field_label_code === "KTITLE"
    )[0]?.data ?? null;

  const sustainAbilityDescription =
    sustainAbilityData?.[0]?.section_elements.filter(
      (items) => items.field_label_code === "KSUSDESCRIPTION"
    )[0]?.data ?? null;

  const experienceAwaitData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "TROPICALLUXURY"
      )[0] ?? null
  );
  const experienceAwaitIcon =
    experienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "KICON"
    )[0]?.data ?? null;
  const experienceAwaitHeading =
    experienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "KHEADING"
    )[0]?.data ?? null;
  const welcomeMessage =
    experienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "KMESSAGE"
    )[0]?.data ?? null;
    const para =
    experienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "KDESCRIPTION"
    )[0]?.data ?? null;
  const threeDButtonLink =
    experienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "KLINK"
    )[0]?.data ?? null;
   
    const iceLandPdfLink =
    experienceAwaitData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "KPDF"
    )[0]?.data ?? null;
  // let BannerVideoObject = pagesSectionData1?.data?.sectionData?.filter(
  //   (item) =>
  //     item.section_code === "MAINBANNER" &&
  //     item.field_label_code === "KADDVIDEO"
  // )[0];

  // let BannerVideo = BannerVideoObject?.psdd_data ?? bannerCover;
 
  const planExploreHeading = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details
        ?.filter((item) => item.section_code === "PLAN")[0]
        ?.section_elements?.filter(
          (item) => item.field_label_code === "KHEADING"
        )[0]?.data ?? null
  );

  const homePageSliderDataArray = pagesSectionData?.data?.data[0].section_details?.find(data=> data.section_code === "MAINBANNER");
  
  const SliderData = homePageSliderDataArray?.subSectionData?.map((item)=> {
      let altTag =""
      const imageElement = item?.elements?.find(
        (item) => item.field_title_code === "IMAGE"
      ).data;
      const imageSpaElementAltTag = item?.elements?.find(
        (item) => item.field_title_code === "IMAGE"
      ).img_alt_tag;
     if(imageSpaElementAltTag===null)
    {
      altTag=""
    }
      return{
        slideImg:imageElement,       
        ImgAltTag:altTag
      }
    }
    );


  // const planExploreButton = pagesSectionData?.data?.data?.map(
  //   (sectionData) =>
  //     sectionData?.section_details
  //       ?.filter((item) => item.section_code === "PLAN")[0]
  //       ?.section_elements?.filter(
  //         (item) => item.field_label_code === "KEXPLOREBUTTON"
  //       )[0]?.data ?? null
  // );
  const planExploreImage = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details
        ?.filter((item) => item.section_code === "PLAN")[0]
        ?.section_elements?.filter(
          (item) => item.field_label_code === "KIMAGE"
        )[0]?.data ?? null
  );

  const sustainSection1 = sustainAbilityData?.[0]?.subSectionData?.filter(
    (item) => item.sub_section_title_code === "SECTION1"
  );

  const greenSustainImage =
    sustainSection1
      ?.map((subData) => subData.elements)?.[0]
      ?.filter((i) => i.field_title_code === "SUSIMG")?.[0]?.data ?? null;

  const heading1 =
    sustainSection1
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "THINKGREEN")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "HEADING")?.[0]?.data ?? null;
  const icon1 =
    sustainSection1
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "THINKGREEN")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "ICON")?.[0]?.data ?? null;

  const description1 =
    sustainSection1
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "THINKGREEN")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "DESCRIPTION")?.[0]?.data ?? null;

  const heading2 =
    sustainSection1
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "WATERWISE")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "HEADING")?.[0]?.data ?? null;

  const icon2 =
    sustainSection1
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "WATERWISE")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "ICON")?.[0]?.data ?? null;

  const description2 =
    sustainSection1
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "WATERWISE")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "DESCRIPTION")?.[0]?.data ?? null;

  const sustainSection2 = sustainAbilityData?.[0]?.subSectionData?.filter(
    (item) => item.sub_section_title_code === "SECTION4"
  );

  const greenButtomSustainImage =
    sustainSection2
      ?.map((subData) => subData.elements)?.[0]
      ?.filter((i) => i.field_title_code === "SUSIMG")?.[0]?.data ?? null;

  const heading3 =
    sustainSection2
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "WASTESMART")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "HEADING")?.[0]?.data ?? null;

  const icon3 =
    sustainSection2
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "WASTESMART")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "ICON")?.[0]?.data ?? null;

  const description3 =
    sustainSection2
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "WASTESMART")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "DESCRIPTION")?.[0]?.data ?? null;

  const heading4 =
    sustainSection2
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "ENERGYEFFICIENT")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "HEADING")?.[0]?.data ?? null;

  const icon4 =
    sustainSection2
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "ENERGYEFFICIENT")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "ICON")?.[0]?.data ?? null;

  const description4 =
    sustainSection2
      ?.map((subData) => subData.children)?.[0]
      ?.filter((i) => i.sub_section_title_code === "ENERGYEFFICIENT")
      ?.map((i) => i.elements)?.[0]
      ?.filter((t) => t.field_title_code === "DESCRIPTION")?.[0]?.data ?? null;

  const greenSustainImageAltTag =
    sustainSection1?.[0]?.elements?.find((i) => i.field_title_code === "SUSIMG")
      ?.img_alt_tag ?? null;
  const greenButtomSustainImageAltTag =
    sustainSection2?.[0]?.elements?.find((i) => i.field_title_code === "SUSIMG")
      ?.img_alt_tag ?? null;

  const images = [
    {
      sustainFeatureImg: `${icon1}`,
      sustainHeading: `${heading1}`,
      sustainPara: `${description1}`,
    },

    {
      sustainFeatureImg: `${icon2}`,
      sustainHeading: `${heading2}`,
      sustainPara: `${description2}`,
    },
  ];
  const bottomimages = [
    {
      sustainFeatureImg: `${icon3}`,
      sustainHeading: `${heading3}`,
      sustainPara: `${description3}`,
    },

    {
      sustainFeatureImg: `${icon4}`,
      sustainHeading: `${heading4}`,
      sustainPara: `${description4}`,
    },
  ];

  const PlanData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "PLAN"
      )[0] ?? null
  );



  const exploreKaniPlanButton=PlanData && PlanData[0]?.section_elements?.filter(item=>
    item.field_label_code==="KEXPLOREBUTTON"
  )[0]?.data

  // const truncateText = (text = "", length) => {
  //   return text?.length > +length ? text.slice(0, +length) + "..." : text;
  // };

  let keywords = pagesSectionData?.data?.data[0]?.meta_keyword;
  let slug = pagesSectionData?.data?.data[0]?.slug;
  let meta_title = pagesSectionData?.data?.data[0]?.meta_title;
  let meta_description = pagesSectionData?.data?.data[0]?.meta_description;
  const { setIsLoadingMain } = useContext(MyContext);
  const { isLoading, isSuccess } = pagesSectionData1;
  if (isLoading) {
    setIsLoadingMain(true);
    return;
  }
  if (isSuccess) {
    setIsLoadingMain(false);
  }
  
  return (
    <>
      <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />

      <HomeVideo AtmosphereVideo={SliderData} />

      {/* <BookNow /> */}
      <ExperienceAwait
        smallHeading={welcomeMessage}
        para={para}
        showHeading={false}
        image={experienceAwaitIcon}
        heading={experienceAwaitHeading}
        iceLandPdfLink={iceLandPdfLink}
        threeDButtonLink={threeDButtonLink}
        showFirstButton={true}
        showSecondButton={true}
      />
      <KanifushPlan
        planExploreHeading={planExploreHeading}
        planExploreButton={exploreKaniPlanButton}
        planExploreImage={planExploreImage}
        PlanData={PlanData}
        planSlug={planSlug}
      />
      {/* <SpecialOffers
       offersSlug={offersSlug}
       slugOfDiningOffers={slugOfDiningOffers}
        slugOfRoomOffers={slugOfRoomOffers}
        slugOfSpaOffers={slugOfSpaOffers} 
        /> */}
      <ExploreVillas villaSlug={villaSlug} />
      <Sustainability
        greenSustain={""}
        greenBottomSustain={""}
        images={images}
        bottomimages={bottomimages}
        sustainPara={sustainAbilityDescription}
        sustainAbility={sustainAbilityHeading}
        greenSustainImage={greenSustainImage}
        greenButtomSustainImage={greenButtomSustainImage}
        greenButtomSustainImageAltTag={greenButtomSustainImageAltTag}
        greenSustainImageAltTag={greenSustainImageAltTag}
        sustainibilitySlug={sustainibilitySlug}
        showTopLink={false}
      />
      <Glimpses
        image1={Image1}
        image2={Image2}
        image3={Image3}
        image4={Image4}
        image5={Image5}
        image6={Image6}
        bottomLeftImagealt={bottomLeftImagealt}
        topRightImagealt={topRightImagealt}
        bottomCenterImagealt={bottomCenterImagealt}
        topCenterImagealt={topCenterImagealt}
        bottomRightImagealt={bottomRightImagealt}
        topLeftImagealt={topLeftImagealt}
        slugOfGallery={slugOfGallery}
      />
    </>
  );
};

export default Home;
